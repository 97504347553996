exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-research-802-js": () => import("./../../../src/pages/research/802.js" /* webpackChunkName: "component---src-pages-research-802-js" */),
  "component---src-pages-research-814-js": () => import("./../../../src/pages/research/814.js" /* webpackChunkName: "component---src-pages-research-814-js" */),
  "component---src-pages-research-826-js": () => import("./../../../src/pages/research/826.js" /* webpackChunkName: "component---src-pages-research-826-js" */),
  "component---src-pages-research-847-js": () => import("./../../../src/pages/research/847.js" /* webpackChunkName: "component---src-pages-research-847-js" */),
  "component---src-pages-research-index-js": () => import("./../../../src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */),
  "component---src-pages-statistics-index-js": () => import("./../../../src/pages/statistics/index.js" /* webpackChunkName: "component---src-pages-statistics-index-js" */),
  "component---src-pages-visualizations-all-barchart-js": () => import("./../../../src/pages/visualizations/all/barchart.js" /* webpackChunkName: "component---src-pages-visualizations-all-barchart-js" */),
  "component---src-pages-visualizations-all-bubblechart-js": () => import("./../../../src/pages/visualizations/all/bubblechart.js" /* webpackChunkName: "component---src-pages-visualizations-all-bubblechart-js" */),
  "component---src-pages-visualizations-all-circlepacking-js": () => import("./../../../src/pages/visualizations/all/circlepacking.js" /* webpackChunkName: "component---src-pages-visualizations-all-circlepacking-js" */),
  "component---src-pages-visualizations-all-forcedirected-js": () => import("./../../../src/pages/visualizations/all/forcedirected.js" /* webpackChunkName: "component---src-pages-visualizations-all-forcedirected-js" */),
  "component---src-pages-visualizations-all-radialtreetidy-js": () => import("./../../../src/pages/visualizations/all/radialtreetidy.js" /* webpackChunkName: "component---src-pages-visualizations-all-radialtreetidy-js" */),
  "component---src-pages-visualizations-index-js": () => import("./../../../src/pages/visualizations/index.js" /* webpackChunkName: "component---src-pages-visualizations-index-js" */),
  "component---src-templates-entry-concept-js": () => import("./../../../src/templates/entry-concept.js" /* webpackChunkName: "component---src-templates-entry-concept-js" */),
  "component---src-templates-entry-human-development-js": () => import("./../../../src/templates/entry-humanDevelopment.js" /* webpackChunkName: "component---src-templates-entry-human-development-js" */),
  "component---src-templates-entry-news-js": () => import("./../../../src/templates/entry-news.js" /* webpackChunkName: "component---src-templates-entry-news-js" */),
  "component---src-templates-entry-pattern-js": () => import("./../../../src/templates/entry-pattern.js" /* webpackChunkName: "component---src-templates-entry-pattern-js" */),
  "component---src-templates-entry-problem-js": () => import("./../../../src/templates/entry-problem.js" /* webpackChunkName: "component---src-templates-entry-problem-js" */),
  "component---src-templates-entry-strategy-js": () => import("./../../../src/templates/entry-strategy.js" /* webpackChunkName: "component---src-templates-entry-strategy-js" */),
  "component---src-templates-entry-value-js": () => import("./../../../src/templates/entry-value.js" /* webpackChunkName: "component---src-templates-entry-value-js" */),
  "component---src-templates-list-concept-js": () => import("./../../../src/templates/list-concept.js" /* webpackChunkName: "component---src-templates-list-concept-js" */),
  "component---src-templates-list-humandevelopment-js": () => import("./../../../src/templates/list-humandevelopment.js" /* webpackChunkName: "component---src-templates-list-humandevelopment-js" */),
  "component---src-templates-list-news-js": () => import("./../../../src/templates/list-news.js" /* webpackChunkName: "component---src-templates-list-news-js" */),
  "component---src-templates-list-pattern-js": () => import("./../../../src/templates/list-pattern.js" /* webpackChunkName: "component---src-templates-list-pattern-js" */),
  "component---src-templates-list-problem-js": () => import("./../../../src/templates/list-problem.js" /* webpackChunkName: "component---src-templates-list-problem-js" */),
  "component---src-templates-list-strategy-js": () => import("./../../../src/templates/list-strategy.js" /* webpackChunkName: "component---src-templates-list-strategy-js" */),
  "component---src-templates-list-value-js": () => import("./../../../src/templates/list-value.js" /* webpackChunkName: "component---src-templates-list-value-js" */)
}

